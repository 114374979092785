import { Dropzone } from "dropzone";
import { Scrambler } from "./scrambler.js";

const dropzone = new Dropzone(".file-dropzone", { url: "/upload", acceptedFiles: ".docx,.doc" });

const submitBtn = document.querySelector("#submitBtn");
const submitBtnFree = document.querySelector("#submitBtnFree");
const inputText = document.querySelector("#inputText");
const copyBtn = document.querySelector("#copyBtn");
const scramblingText = document.querySelector("#scramblingText");
const fileDropzone = document.querySelector(".file-dropzone");
const resetBtn = document.querySelector("#resetBtn");
const downloadBtn = document.querySelector("#downloadBtn");
const html = document.querySelector("html");

// when the user is dragging a file on the screen, increment the drag counter and show the dropzone
html.addEventListener("dragover", (e) => {
  e.preventDefault();
  e.stopPropagation();
  if (fileDropzone.classList.contains("d-none")) {
    fileDropzone.classList.remove("d-none");
  }
});

fileDropzone.addEventListener("mouseleave", () => {
  fileDropzone.classList.add("d-none");
});

fileDropzone.addEventListener("mouseenter", () => {
  fileDropzone.classList.remove("d-none");
});

// add event listeners for success and error events
dropzone.on("success", function (file, response) {
  fileDropzone.classList.add("d-none");
  // TODO Remove any loading screens
  console.log("File uploaded successfully. Server response:", response);
  const { diffTextResult, finalTextResult } = response;

  displayResults(diffTextResult, finalTextResult);
});

dropzone.on("error", function (file, errorMessage) {
  console.log(errorMessage);
  setTimeout(() => {
    fileDropzone.classList.add("d-none");
    dropzone.removeAllFiles();
  }, 2000);
});

if (submitBtn) {
  submitBtn.addEventListener("click", async () => {
    try {
      // Hide submitbtn
      submitBtn.classList.add("disabled");
      submitBtn.disabled = true;
      await handleResults();
    } catch (e) {
      console.log(e);
      showWarning("Der skete en fejl. Prøv igen senere");
      resetPage();
    }
  });
}

async function handleResults() {
  const text = inputText.innerText;
  if (!text) return showWarning("Du mangler at skrive en tekst");

  // Check length in words
  const words = countWords(text);
  if (words < 100) return showWarning("Din tekst skal være mindst 100 ord lang");

  const { isAuthenticated, isPaying } = await getPaymentStatus();
  console.log("Auth", "Paying", { isAuthenticated, isPaying });

  if (!isAuthenticated) {
    const signupModal = document.getElementById("signUp");
    if (signupModal) {
      const signupModalInstance = new bootstrap.Modal(signupModal, {});
      if (signupModalInstance) return signupModalInstance.show();
    }
  }

  // Check if free user
  if (!isPaying) {
    // Check that LocalStorage lastFreeDetectorCheck (date) is more than 24 hours ago
    const lastFreeDetectorCheck = localStorage.getItem("lastFreeDetectorCheck");
    if (lastFreeDetectorCheck) {
      const now = new Date();
      const lastCheck = new Date(lastFreeDetectorCheck);
      const diff = now.getTime() - lastCheck.getTime();
      const hours = Math.floor(diff / (1000 * 60 * 60));
      if (hours < 24) {
        return showWarning("Du kan kun bruge denne funktion én gang om dagen som gratisbruger");
      }
    }
  }

  // Upload it with fetch
  const body = {
    text,
  };

  // Hide inputtext
  inputText.classList.add("d-none");

  // Add inputtext to scrambling text
  scramblingText.innerHTML = text;

  // Set scrambling animation in motion
  try {
    Scrambler({
      target: "#scramblingText",
      random: [200000, 600000],
      speed: 80,
    });
    scramblingText.classList.remove("d-none");
  } catch (err) {
    console.log(err);
  }

  const data = await postRequest("/check", body);

  // Check if data.error
  if (data.error) {
    showWarning(data.error);
    resetPage();
    return;
  } else {
    localStorage.setItem("lastFreeDetectorCheck", new Date());
  }

  // Handle response data
  const { originalityScore, copyscapeScore, gptZeroScore, detectorWords } = data;
  console.log("GPT-Zero", gptZeroScore);
  console.log("Originality", originalityScore);
  console.log("Copyscape", copyscapeScore);

  let gptZeroScoreNumber;

  if (gptZeroScore && gptZeroScore.documents) {
    const averageGeneratedProb = gptZeroScore.documents[0].average_generated_prob;
    console.log({ averageGeneratedProb });
    gptZeroScoreNumber = 100 - Math.round(gptZeroScore.documents[0].average_generated_prob * 1000) / 10;
    console.log(gptZeroScoreNumber);
    updateProgressBar(gptZeroScoreNumber, "gptzeroScore");
    // Add GPTZero colors to text
    const sentences = gptZeroScore.documents[0].sentences;
    const newText = createColoredSentenceArray(text, sentences);
    inputText.innerHTML = newText;
  }

  const originalityScoreNumber = Math.round(+originalityScore.original * 1000) / 10;
  const copyscapeScoreNumber = copyscapeScore.count > 0 ? 0 : 100;
  const copyScapeLink = copyscapeScore.allviewurl;

  updateProgressBar(originalityScoreNumber, "originalityScore");
  updateProgressBar(copyscapeScoreNumber, "copyscapeScore");

  const copyscapeScoreDom = document.querySelector("#copyscapeScore");
  copyscapeScoreDom.addEventListener("click", () => {
    window.open(copyScapeLink, "_blank");
  });

  // Hide scrambling animation
  scramblingText.classList.add("d-none");

  // Show input text
  inputText.classList.remove("d-none");

  // Update words left
  if (detectorWords) {
    // Find data-bs-target="#upgrade"
    const upgradeBtn = document.querySelector('[data-bs-target="#upgrade"]');
    if (upgradeBtn) {
      upgradeBtn.textContent = `Ord: ${detectorWords}`;
    }
  }

  // Scroll to outputSection
  const outputSection = document.querySelector("#resultsSection");
  if (outputSection) {
    outputSection.classList.remove("d-none");
    outputSection.scrollIntoView({ behavior: "smooth" });
  }
}

async function getPaymentStatus() {
  try {
    const res = await fetch("/api/authenticated");
    const data = await res.json();
    const { isAuthenticated, isPaying } = data;

    // Reset counter if this is the first time the user isAuthenticated
    if (isAuthenticated === true) {
      const firstTimeAuthenticated = localStorage.getItem("firstTimeAuthenticated");
      if (firstTimeAuthenticated === true) {
        localStorage.setItem("counter", 3);
        localStorage.setItem("firstTimeAuthenticated", true);
      }
    }
    return { isAuthenticated, isPaying };

  } catch (err) {
    console.log(err);
    return { isAuthenticated: null, isPaying: null };
  }
}

function createColoredSentenceArray(originalText, sentences) {
  // Loop through sentences, find and replace to keep structure of originalText
  let newText = originalText;

  sentences.forEach((sentence) => {
    const { sentence: sentenceText, generated_prob } = sentence;
    const color = generated_prob === 0 ? "green" : "red";
    const coloredSentence = `<span style="color: ${color}">${sentenceText}</span>`;
    newText = newText.replace(sentenceText, coloredSentence);
  });

  return newText;
}

if (submitBtnFree) {
  submitBtnFree.addEventListener("click", async () => {
    // Hide submitbtn
    submitBtnFree.classList.add("disabled");
    submitBtnFree.disabled = true;
    try {
      await handleResults();
    } catch (e) {
      console.log(e);
      showWarning("Der skete en fejl. Prøv igen senere");
      resetPage();
      localStorage.removeItem("lastFreeDetectorCheck");
    } finally {
      submitBtnFree.classList.remove("disabled");
      submitBtnFree.disabled = false;
    }
  });
}

function resetPage() {
  console.log("Resetting page");
  // Hide scrambler
  scramblingText.classList.add("d-none");

  // Show input text
  inputText.classList.remove("d-none");

  // Enable submit button
  if (submitBtn) submitBtn.classList.remove("disabled");
  if (submitBtn) submitBtn.disabled = false;

  if (submitBtnFree) submitBtnFree.classList.remove("disabled");
  if (submitBtnFree) submitBtnFree.disabled = false;

  // Reset progress bars
  // updateProgressBar(0, "originalityScore");
  // updateProgressBar(0, "copyscapeScore");
  // updateProgressBar(0, "gptzeroScore");
}

// Avoid pasting in HTML and rich text by replacing it with plain text
stripHTMLwhenPasting();
function stripHTMLwhenPasting() {
  const contenteditableFields = document.querySelectorAll("[contenteditable]");
  contenteditableFields.forEach((field) => {
    if (field.dataset.skip) return;

    field.addEventListener("paste", function (e) {
      // cancel paste
      e.preventDefault();

      // get text representation of clipboard
      var text = (e.originalEvent || e).clipboardData.getData("text/plain");

      // encode line breaks with <br> elements
      text = text.replace(/(?:\r\n|\r|\n|\u2028|\u2029)/g, "<br>");

      // insert modified text into field
      document.execCommand("insertHTML", false, text);
    });
  });
}

initResetBtn();
function initResetBtn() {
  if (!resetBtn) return;

  resetBtn.addEventListener("click", (e) => {
    // Show nav bar and hide placeholder
    document.getElementById("resultsTab").classList.add("d-none");
    document.querySelector(".placeholder").classList.remove("d-none");

    // Reset results tab
    document.querySelector("[original]").classList.remove("active");
    document.querySelector("[edits]").classList.add("active");
    document.querySelector("[final]").classList.remove("active");

    // Enable submit btn
    submitBtn.classList.remove("disabled");
    submitBtn.disabled = false;

    // Hide text
    inputText.classList.remove("d-none");
    diffText.classList.add("d-none");
    finalText.classList.add("d-none");

    // Hide scrambling text
    scramblingText.classList.add("d-none");

    // Clear text
    inputText.innerText = "";
    diffText.innerText = "";
    finalText.innerText = "";

    // Focus on text
    inputText.focus();

    // Hide reset btn
    resetBtn.classList.add("d-none");
    downloadBtn.classList.add("d-none");
    copyBtn.classList.add("d-none");
    submitBtn.classList.remove("d-none");
  });
}

// On typing into inputTextArea (contenteditable), count word and update wordCounter
const wordCounter = document.getElementById("wordCounter");
inputText.addEventListener("input", () => {
  const wordCount = countWords();
  wordCounter.innerText = wordCount;
});

export function countWords() {
  let wordCount;

  // Get all words in results;
  const results = document.getElementById("inputText");
  if (!results) wordCount = 0;
  else {
    if (results.innerText.length === 0) {
      wordCount = 0;
    } else {
      let text = results.innerText
        .replace(/&amp;/g, "og")
        .replace(/[.,()"'!;\n\r]/g, " ")
        .replace(/&amp;|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/gi, " ")
        .replace("  ", " ");

      let arr = text ? text.split(/\s+/).filter((n) => n !== "" && n !== " ") : [];
      wordCount = arr.length;
    }
  }

  return wordCount;
}

export function showWarning(optionalText = "", delay = 5000) {
  if (typeof bootstrap === "undefined") return;

  try {
    const warningToast = document.querySelector("#warningToast");
    if (!warningToast) {
      alert(optionalText);
      return;
    }

    // if optionaltext is an object, extract the messaage compnonent
    if (typeof optionalText === "object") optionalText = optionalText.message;

    if (optionalText && optionalText.length > 0) {
      warningToast.querySelector(".toast-body").innerHTML = optionalText;
      warningToast.querySelector("#timing").innerText = new Date().toLocaleTimeString();
    }

    const newToast = new bootstrap.Toast(warningToast, { delay: delay });
    newToast.show();
    // window.scrollTo(0, 0);
  } catch (e) {
    console.log(e);
  }
}

export function showSuccess(optionalText = "", delay = 2500) {
  if (typeof bootstrap === "undefined") return;

  try {
    const successToast = document.querySelector("#successToast");
    if (!successToast) {
      alert(optionalText);
      return;
    }

    if (optionalText.length > 0) {
      successToast.querySelector(".toast-body").innerHTML = optionalText;
      successToast.querySelector("#timing").innerText = new Date().toLocaleTimeString();
    }

    const newToast = new bootstrap.Toast(successToast, { delay });
    newToast.show();
  } catch (e) {
    console.log(e);
  }
}

timeBasedReminder();
function timeBasedReminder() {
  const userIsLoggedInAndPaying = document.getElementById("userIsLoggedInAndPaying");

  if (!userIsLoggedInAndPaying) {
    closeAllOpenModals();
    // Get modal with id "timeBasedUpsell"
    const modal = document.getElementById("timeBasedUpsell");
    const timeBasedModal = new bootstrap.Modal(modal, {});
    // show after 5 minutes of activity
    setTimeout(function () {
      closeAllOpenModals();
      timeBasedModal.show();
    }, 300000);
  }
}

function closeAllOpenModals() {
  // Close all other modals
  const modals = document.querySelectorAll(".modal");
  modals.forEach((modal) => {
    const modalInstance = bootstrap.Modal.getInstance(modal);
    if (modalInstance) {
      modalInstance.hide();
    }
  });
}

export async function postRequest(url, data, timeout = 30000) {
  // Remove all queries and hashes from the url
  url = url.split("?")[0].split("#")[0];

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      signal: controller.signal,
    });

    clearTimeout(id);

    try {
      return await response.json();
    } catch (error) {
      console.error(`Error parsing response body: ${error}`);
      return { error: "Error parsing response body" };
    }
  } catch (error) {
    if (error.name === "AbortError") {
      console.error(`Request timed out after ${timeout}ms`);
      showWarning("Der gik noget frygteligt galt i processen. Prøv igen senere.");
      return { error: "Request timed out" };
    } else {
      console.error(`Request failed: ${error}`);
      return { error: "Request failed" };
    }
  }
}

initTooltips();
function initTooltips() {
  if (typeof bootstrap === "undefined") return;

  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  const tooltipList = [...tooltipTriggerList].map(
    (tooltipTriggerEl) =>
      new bootstrap.Tooltip(tooltipTriggerEl, {
        trigger: "hover",
      })
  );
}

const toggleClass = (element, className, shouldAdd) => {
  if (shouldAdd) {
    element.classList.add(className);
  } else {
    element.classList.remove(className);
  }
};

const handleScramblingAnimation = () => {
  try {
    Scrambler({
      target: "#scramblingText",
      random: [20000, 60000],
      speed: 80,
    });
    toggleClass(scramblingText, "d-none", false);
  } catch (err) {
    console.log(err);
  }
};

function updateProgressBar(score, id) {
  console.log(id, score);
  // Validatei nputs
  if (typeof score !== "number" || score < 0 || score > 100) {
    return console.error("Score must be a number between 0 and 100");
  }

  if (typeof id !== "string" || id.length === 0) {
    console.error("Id must be a string");
    return;
  }

  const progressBar = document.querySelector(`#${id}.progress-bar`);
  const progressOverlay = document.querySelector(`#${id} .progress-bar__overlay`);
  const progressValue = document.querySelector(`#${id} .progress-bar__value`);

  if (!progressBar || !progressOverlay || !progressValue) return;

  progressBar.setAttribute("aria-valuenow", score);
  progressValue.textContent = score;

  const circumference = 2 * Math.PI * 20;
  const offset = circumference * (1 - score / 100);

  progressOverlay.style.strokeDasharray = `${circumference}`;
  progressOverlay.style.strokeDashoffset = `${offset}px`;

  progressOverlay.style.stroke = "green";
}

storeEntries();
export function storeEntries() {
  const allInputAndTextAreas = document.querySelectorAll("div[contentEditable='true'], input, textarea");
  if (!allInputAndTextAreas) return;
  allInputAndTextAreas.forEach((el) => {
    if (el.type === "password") return;
    if (el.type === "email") return;

    el.addEventListener("keyup", () => store(el));
    el.addEventListener("click", () => store(el));
    el.addEventListener("change", () => store(el));
  });
}

// 📦 Store the value of an element in local storage
function store(el) {
  if (el.tagName === "TEXTAREA" || el.tagName === "INPUT") {
    let val = purge(el.value);
    if (!val) val = "";
    localStorage.setItem(el.id, val);
  } else {
    if (el.innerText.length <= 1) {
      localStorage.setItem(el.id, "");
    } else {
      let val = purge(el.innerHTML);
      if (!val) val = "";
      localStorage.setItem(el.id, val);
    }
  }
}

function purge(html) {
  if (!html) return;
  html = html.replace(/<span.*?>|<\/span>/g, ""); // Remove all <span> tags
  html = html.replace(/<del>.*?<\/del>/g, ""); // Removes <del> ... </del>
  html = html.replace(/<ins.*?>|<\/ins>/g, ""); // Removes only the <ins> tags
  return html;
}

// On load bring topic etc back from localstorage
recallEntries();
const wordCount = countWords();
wordCounter.innerText = wordCount;
export function recallEntries() {
  const allInputAndTextAreas = document.querySelectorAll("div[contentEditable='true'], div[contenteditable='true'],input, textarea");
  if (!allInputAndTextAreas) return;

  allInputAndTextAreas.forEach((el) => {
    if (el.id === "results") return;

    if (el.tagName === "TEXTAREA" || el.tagName === "INPUT") {
      // If the el has the property skip, then skip it
      if (el.dataset.skip === "true") {
        console.log("Skipping", el.id);
        return;
      }

      // If it is already containing something, then skip it
      if (el.value) return;
      let valueInStorage = localStorage.getItem(el.id);
      if (!valueInStorage) valueInStorage = "";
      if (valueInStorage === "undefined") valueInStorage = "";

      valueInStorage = valueInStorage ? valueInStorage.replaceAll("undefined", "") : "";
      valueInStorage = valueInStorage ? valueInStorage.replaceAll("<br>", "\n") : "";
      valueInStorage = valueInStorage ? valueInStorage.replaceAll("</br>", "\n") : "";

      el.value = valueInStorage;
    } else {
      if (el.innerText) return;
      let valueInStorage = localStorage.getItem(el.id);
      if (!valueInStorage) valueInStorage = "";
      if (valueInStorage === "undefined") valueInStorage = "";

      el.innerHTML = valueInStorage;
    }
  });
}
